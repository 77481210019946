<template>
  <div class="hero">
    <DesktopHero class="d-none d-xl-block desktop-hero" />
    <TabletHero
      class="d-none d-md-block d-xl-none tablet-hero"
      style="margin-left: -325px"
    />
    <div class="w-100 d-flex justify-content-center">
      <MobileHero class="d-md-none mobile-hero" />
    </div>
    <div class="my-text">
      <h1 class="light-theme-title">{{ $t("404.hero.title") }}</h1>
      <div class="bottom-part">
        <h4 class="light-theme-title subtitle-bodyLarge">
          {{ $t("404.hero.subtitle") }}
        </h4>
        <div class="text-center">
          <router-link class="link" to="/">
            <b-button
              variant="primary"
              style="margin-top: 24px"
              class="round-btn-19-20 hero-button"
            >
              <p class="button-large">
                <LeftArrow /> {{ $t("404.hero.back") }}
              </p>
            </b-button></router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DesktopHero from "../../svg/hero/DesktopHero.vue";
import TabletHero from "../../svg/hero/TabletHero.vue";
import MobileHero from "../../svg/hero/MobileHero.vue";
import LeftArrow from "../../svg/hero/LeftArrow.vue";

export default {
  components: { DesktopHero, TabletHero, MobileHero, LeftArrow },
};
</script>

<style lang="scss" scoped>
.bottom-part {
  position: absolute;
  width: 100%;
  @media (max-width: 767px) {
    bottom: 70px;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    bottom: 0px;
  }
  @media (min-width: 1200px) {
  }
}
.mobile-hero {
  margin: 30% auto 70% auto;
}
.tablet-hero {
  top: -35%;
  @media (min-width: 768px) and (max-width: 991px) {
    transform: translateX(-155px);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    transform: translateX(-35px);
  }
}
.desktop-hero {
  transform: translateX(-275px);
}

.my-text {
  position: absolute;
  @media (min-width: 1200px) {
    top: 110px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    top: 10px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    top: 10px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    top: 0px;
  }
  @media (max-width: 575px) {
    top: 30px;
  }
  bottom: 0;
  left: 0;
  right: 0;
  /* margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto; */
  height: 85%;
}
h1 {
  text-align: center;

  /* identical to box height, or 64px */

  /* Light Theme/Title Color */

  color: #39454c;
}
h4 {
  /* identical to box height, or 29px */
  text-align: center;

  /* Light Theme/Title Color */
}
@media (max-width: 767px) {
  .my-text {
    margin-top: 0;
  }
  h1 {
    margin: 10% auto;
  }
  h4 {
    max-width: 221px;

    margin: 0 auto 0 auto !important;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  h1 {
    margin: 10% auto;
    width: 100%;
  }
  h4 {
    margin-top: 40%;
    margin-bottom: 4%;
  }
}
@media (min-width: 1200px) {
  h1 {
    margin: 10% auto;
    width: 100%;
  }
  h4 {
    margin-top: 30%;
    margin-bottom: 4%;
  }
}
</style>
