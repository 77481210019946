var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "hero"
  }, [_c('DesktopHero', {
    staticClass: "d-none d-xl-block desktop-hero"
  }), _c('TabletHero', {
    staticClass: "d-none d-md-block d-xl-none tablet-hero",
    staticStyle: {
      "margin-left": "-325px"
    }
  }), _c('div', {
    staticClass: "w-100 d-flex justify-content-center"
  }, [_c('MobileHero', {
    staticClass: "d-md-none mobile-hero"
  })], 1), _c('div', {
    staticClass: "my-text"
  }, [_c('h1', {
    staticClass: "light-theme-title"
  }, [_vm._v(_vm._s(_vm.$t("404.hero.title")))]), _c('div', {
    staticClass: "bottom-part"
  }, [_c('h4', {
    staticClass: "light-theme-title subtitle-bodyLarge"
  }, [_vm._v(" " + _vm._s(_vm.$t("404.hero.subtitle")) + " ")]), _c('div', {
    staticClass: "text-center"
  }, [_c('router-link', {
    staticClass: "link",
    attrs: {
      "to": "/"
    }
  }, [_c('b-button', {
    staticClass: "round-btn-19-20 hero-button",
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      "variant": "primary"
    }
  }, [_c('p', {
    staticClass: "button-large"
  }, [_c('LeftArrow'), _vm._v(" " + _vm._s(_vm.$t("404.hero.back")) + " ")], 1)])], 1)], 1)])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }