var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M7.43179 5.44891C7.12743 5.16795 6.65293 5.18693 6.37198 5.49129L3.9489 8.11626C3.6837 8.40356 3.6837 8.84639 3.9489 9.13368L6.37197 11.7587C6.65293 12.0631 7.12742 12.0821 7.43179 11.8011C7.73615 11.5202 7.75514 11.0457 7.47419 10.7413L6.21298 9.37498L13.5 9.37498C13.9142 9.37498 14.25 9.03919 14.25 8.62498C14.25 8.21076 13.9142 7.87498 13.5 7.87498L6.213 7.87498L7.47418 6.50872C7.75513 6.20435 7.73616 5.72986 7.43179 5.44891Z",
      "fill": "#FCFCFD"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }