var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "1704",
      "height": "885",
      "viewBox": "0 0 1704 885",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M1586.35 2C1616.96 130.79 1625.09 400.197 1412.7 447.509C1147.21 506.649 1024.1 268.982 793.48 362.824C562.859 456.665 490.681 819.552 298.253 752.342C144.31 698.573 77.3184 710.181 63.0655 722.707",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-linecap": "round",
      "stroke-dasharray": "6 6"
    }
  }), _c('g', {
    attrs: {
      "filter": "url(#filter0_d_2004_42626)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M701.994 466.84H727.937V503.474H701.994V553H656.556V503.474H562.691L560.647 474.858L656.084 324.078H701.994V466.84ZM605.929 466.84H656.556V386.025L653.568 391.214L605.929 466.84ZM1114.57 466.84H1140.51V503.474H1114.57V553H1069.13V503.474H975.266L973.223 474.858L1068.66 324.078H1114.57V466.84ZM1018.5 466.84H1069.13V386.025L1066.14 391.214L1018.5 466.84Z",
      "fill": "#39454C"
    }
  })]), _c('g', {
    attrs: {
      "filter": "url(#filter1_d_2004_42626)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M854.051 319.533C849.347 319.738 844.642 320.623 839.867 322.189C818.521 329.269 800.757 345.335 786.854 362.082C773.864 377.672 764.174 395.713 757.433 414.707C736.929 472.505 747.953 549.502 809.323 579.593C822.945 586.264 838.042 589.941 853.279 590.553C910.014 592.732 951.231 549.57 958.464 497.286C966.117 441.734 944.912 374.949 900.675 337.574C887.264 326.205 871.114 318.717 854.051 319.533Z",
      "fill": "#07B53B"
    }
  })]), _c('path', {
    attrs: {
      "d": "M838.874 496.706C837.615 496.706 836.579 495.681 836.745 494.433C836.942 492.943 837.333 491.482 837.911 490.087C838.78 487.989 840.054 486.082 841.66 484.477C843.266 482.871 845.173 481.597 847.271 480.728C849.369 479.858 851.618 479.411 853.889 479.411C856.16 479.411 858.409 479.858 860.507 480.728C862.606 481.597 864.512 482.871 866.118 484.477C867.724 486.082 868.998 487.989 869.867 490.087C870.445 491.482 870.836 492.943 871.034 494.433C871.199 495.681 870.163 496.706 868.905 496.706C867.646 496.706 866.646 495.677 866.422 494.439C866.261 493.547 866.005 492.673 865.656 491.831C865.016 490.286 864.078 488.882 862.895 487.699C861.713 486.517 860.309 485.578 858.763 484.938C857.218 484.298 855.562 483.969 853.889 483.969C852.217 483.969 850.56 484.298 849.015 484.938C847.47 485.578 846.066 486.517 844.883 487.699C843.7 488.882 842.762 490.286 842.122 491.831C841.774 492.673 841.517 493.547 841.356 494.439C841.132 495.677 840.132 496.706 838.874 496.706Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M908.031 439.578L888.277 458.731",
      "stroke": "white",
      "stroke-width": "5.32137",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M888.281 439.578L908.036 458.731",
      "stroke": "white",
      "stroke-width": "5.32137",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M820.629 439.578L800.874 458.731",
      "stroke": "white",
      "stroke-width": "5.32137",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M800.879 439.578L820.633 458.731",
      "stroke": "white",
      "stroke-width": "5.32137",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M1199.62 356.885L1194.94 366.433L1213.51 368.864L1201.6 388.276L1216.36 403.472C1231.84 398.864 1245.25 388.298 1248.24 371.862C1249.21 366.569 1248.96 361.03 1247.6 355.753C1242.47 336.164 1222.71 326.409 1203.25 329.2C1201.53 329.434 1199.84 329.759 1198.14 330.186L1190.87 352.936L1199.62 356.885Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M449.205 540.722C443.29 541.867 437.802 544.545 434.5 549.191C433.545 550.462 432.804 551.894 432.24 553.458C429.777 560.552 430.551 568.579 432.265 575.71C433.923 582.413 436.938 588.697 440.808 594.407C444.519 599.888 449.398 604.974 455.039 609.031L458.67 589.901L480.545 588.068L472.047 573.1L482.15 571.339L480.277 562.764L502.165 555.7C488.134 544.04 467.268 537.249 449.205 540.722Z",
      "fill": "#07B53B"
    }
  }), _c('defs', [_c('filter', {
    attrs: {
      "id": "filter0_d_2004_42626",
      "x": "556.648",
      "y": "324.078",
      "width": "587.863",
      "height": "236.922",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "in": "SourceAlpha",
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
      "result": "hardAlpha"
    }
  }), _c('feOffset', {
    attrs: {
      "dy": "4"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "2"
    }
  }), _c('feComposite', {
    attrs: {
      "in2": "hardAlpha",
      "operator": "out"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in2": "BackgroundImageFix",
      "result": "effect1_dropShadow_2004_42626"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "effect1_dropShadow_2004_42626",
      "result": "shape"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter1_d_2004_42626",
      "x": "719.913",
      "y": "319.473",
      "width": "268.174",
      "height": "327.334",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "in": "SourceAlpha",
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
      "result": "hardAlpha"
    }
  }), _c('feOffset', {
    attrs: {
      "dy": "28.0868"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "14.0434"
    }
  }), _c('feComposite', {
    attrs: {
      "in2": "hardAlpha",
      "operator": "out"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in2": "BackgroundImageFix",
      "result": "effect1_dropShadow_2004_42626"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "effect1_dropShadow_2004_42626",
      "result": "shape"
    }
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }