var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "1704",
      "height": "885",
      "viewBox": "0 0 1704 885",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M639.141 353.246L631.716 360.68",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M631.719 353.246L639.143 360.68",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M603.141 591.535L595.716 598.969",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M595.719 591.535L603.143 598.969",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M1060 600.835C1060 603.602 1062.24 605.843 1065 605.843C1067.76 605.843 1070 603.602 1070 600.835C1070 598.069 1067.76 595.828 1065 595.828C1062.24 595.828 1060 598.069 1060 600.835Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M1165.14 465.379L1157.72 472.813",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M1157.72 465.379L1165.14 472.813",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M1153 402.593C1153 405.36 1155.24 407.601 1158 407.601C1160.76 407.601 1163 405.36 1163 402.593C1163 399.827 1160.76 397.586 1158 397.586C1155.24 397.586 1153 399.827 1153 402.593Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M527.004 380.566C527.004 383.333 529.244 385.573 532.004 385.573C534.764 385.573 537.004 383.333 537.004 380.566C537.004 377.799 534.764 375.559 532.004 375.559C529.244 375.559 527.004 377.799 527.004 380.566Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M1196 546.769C1196 549.536 1198.24 551.776 1201 551.776C1203.76 551.776 1206 549.536 1206 546.769C1206 544.002 1203.76 541.762 1201 541.762C1198.24 541.762 1196 544.002 1196 546.769Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M946.004 358.539C946.004 361.305 948.244 363.546 951.004 363.546C953.764 363.546 956.004 361.305 956.004 358.539C956.004 355.772 953.764 353.531 951.004 353.531C948.244 353.531 946.004 355.772 946.004 358.539Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M621.004 618.859C621.004 621.626 623.244 623.866 626.004 623.866C628.764 623.866 631.004 621.626 631.004 618.859C631.004 616.092 628.764 613.852 626.004 613.852C623.244 613.852 621.004 616.092 621.004 618.859Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M1586.35 2C1616.96 130.79 1625.09 400.197 1412.7 447.509C1147.21 506.649 1024.1 268.982 793.48 362.824C562.859 456.665 490.681 819.552 298.253 752.342C144.31 698.573 77.3184 710.181 63.0655 722.707",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-linecap": "round",
      "stroke-dasharray": "6 6"
    }
  }), _c('g', {
    attrs: {
      "filter": "url(#filter0_d_1821_42610)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M701.998 491.84H727.94V528.474H701.998V578H656.56V528.474H562.695L560.651 499.858L656.088 349.078H701.998V491.84ZM605.933 491.84H656.56V411.025L653.572 416.214L605.933 491.84ZM1114.57 491.84H1140.52V528.474H1114.57V578H1069.13V528.474H975.27L973.226 499.858L1068.66 349.078H1114.57V491.84ZM1018.51 491.84H1069.13V411.025L1066.15 416.214L1018.51 491.84Z",
      "fill": "#39454C"
    }
  })]), _c('g', {
    attrs: {
      "filter": "url(#filter1_d_1821_42610)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M854.055 328.567C849.351 328.778 844.646 329.692 839.871 331.309C818.525 338.62 800.76 355.211 786.858 372.506C773.867 388.605 764.177 407.235 757.437 426.85C736.933 486.537 747.957 566.049 809.327 597.123C822.949 604.013 838.046 607.809 853.283 608.442C910.018 610.692 951.235 566.12 958.468 512.127C966.121 454.76 944.916 385.793 900.679 347.197C887.268 335.456 871.118 327.723 854.055 328.567Z",
      "fill": "#07B53B"
    }
  })]), _c('path', {
    attrs: {
      "d": "M838.877 496.709C837.619 496.709 836.583 495.685 836.749 494.437C836.946 492.947 837.337 491.486 837.915 490.091C838.784 487.993 840.058 486.086 841.664 484.48C843.27 482.874 845.176 481.601 847.275 480.731C849.373 479.862 851.622 479.415 853.893 479.415C856.164 479.415 858.413 479.862 860.511 480.731C862.61 481.601 864.516 482.874 866.122 484.48C867.728 486.086 869.002 487.993 869.871 490.091C870.449 491.486 870.84 492.947 871.038 494.437C871.203 495.685 870.167 496.709 868.909 496.709V496.709C867.65 496.709 866.65 495.681 866.426 494.443C866.265 493.551 866.009 492.677 865.66 491.835C865.02 490.29 864.082 488.886 862.899 487.703C861.717 486.521 860.312 485.582 858.767 484.942C857.222 484.302 855.566 483.973 853.893 483.973C852.22 483.973 850.564 484.302 849.019 484.942C847.474 485.582 846.07 486.521 844.887 487.703C843.704 488.886 842.766 490.29 842.126 491.835C841.777 492.677 841.521 493.551 841.36 494.443C841.136 495.681 840.136 496.709 838.877 496.709V496.709Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M908.035 452.531L888.281 472.31",
      "stroke": "white",
      "stroke-width": "5.32137",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M888.285 452.531L908.04 472.31",
      "stroke": "white",
      "stroke-width": "5.32137",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M820.633 452.531L800.878 472.31",
      "stroke": "white",
      "stroke-width": "5.32137",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M800.883 452.531L820.637 472.31",
      "stroke": "white",
      "stroke-width": "5.32137",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M1289.89 340.203L1283.01 354.383L1309.82 357.788L1292.4 386.644L1313.57 408.98C1335.99 402 1355.49 386.221 1359.98 361.863C1361.44 354.019 1361.14 345.824 1359.23 338.027C1352.03 309.089 1323.58 294.856 1295.43 299.189C1292.94 299.554 1290.5 300.052 1288.03 300.702L1277.28 334.45L1289.89 340.203Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M376.732 557.365C370.045 558.701 363.842 561.827 360.11 567.25C359.031 568.734 358.193 570.405 357.556 572.231C354.771 580.511 355.646 589.881 357.584 598.206C359.458 606.029 362.866 613.365 367.24 620.03C371.435 626.428 376.949 632.364 383.326 637.1L387.43 614.77L412.157 612.63L402.551 595.159L413.971 593.103L411.853 583.094L436.595 574.848C420.735 561.237 397.149 553.311 376.732 557.365Z",
      "fill": "#07B53B"
    }
  }), _c('defs', [_c('filter', {
    attrs: {
      "id": "filter0_d_1821_42610",
      "x": "556.652",
      "y": "349.078",
      "width": "587.863",
      "height": "236.922",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "in": "SourceAlpha",
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
      "result": "hardAlpha"
    }
  }), _c('feOffset', {
    attrs: {
      "dy": "4"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "2"
    }
  }), _c('feComposite', {
    attrs: {
      "in2": "hardAlpha",
      "operator": "out"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in2": "BackgroundImageFix",
      "result": "effect1_dropShadow_1821_42610"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "effect1_dropShadow_1821_42610",
      "result": "shape"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter1_d_1821_42610",
      "x": "719.917",
      "y": "328.504",
      "width": "268.174",
      "height": "336.193",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "in": "SourceAlpha",
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
      "result": "hardAlpha"
    }
  }), _c('feOffset', {
    attrs: {
      "dy": "28.0868"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "14.0434"
    }
  }), _c('feComposite', {
    attrs: {
      "in2": "hardAlpha",
      "operator": "out"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in2": "BackgroundImageFix",
      "result": "effect1_dropShadow_1821_42610"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "effect1_dropShadow_1821_42610",
      "result": "shape"
    }
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }