var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "341",
      "height": "277",
      "viewBox": "0 0 341 277",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "filter": "url(#filter0_d_1329_41427)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M78.7231 185.78H92.3388V205.007H78.7231V231H54.8751V205.007H5.61129L4.53855 189.988L54.6276 110.852H78.7231V185.78ZM28.304 185.78H54.8751V143.365L53.3073 146.088L28.304 185.78ZM295.26 185.78H308.875V205.007H295.26V231H271.412V205.007H222.148L221.075 189.988L271.164 110.852H295.26V185.78ZM244.841 185.78H271.412V143.365L269.844 146.088L244.841 185.78Z",
      "fill": "#39454C"
    }
  })]), _c('g', {
    attrs: {
      "filter": "url(#filter1_d_1329_41427)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M158.535 100.576C156.066 100.686 153.597 101.166 151.091 102.013C139.888 105.846 130.564 114.543 123.267 123.609C116.449 132.048 111.364 141.814 107.826 152.096C97.0647 183.384 102.851 225.064 135.06 241.353C142.209 244.965 150.133 246.955 158.13 247.287C187.907 248.466 209.539 225.101 213.335 196.798C217.352 166.727 206.223 130.574 183.005 110.342C175.966 104.187 167.49 100.134 158.535 100.576Z",
      "fill": "#07B53B"
    }
  })]), _c('path', {
    attrs: {
      "d": "M150.57 189.091C149.909 189.091 149.366 188.553 149.452 187.899C149.556 187.116 149.761 186.35 150.065 185.618C150.521 184.516 151.189 183.516 152.032 182.673C152.875 181.83 153.876 181.161 154.977 180.705C156.078 180.249 157.259 180.014 158.45 180.014C159.642 180.014 160.823 180.249 161.924 180.705C163.025 181.161 164.026 181.83 164.869 182.673C165.712 183.516 166.38 184.516 166.836 185.618C167.14 186.35 167.345 187.116 167.449 187.899C167.535 188.553 166.992 189.091 166.331 189.091C165.671 189.091 165.146 188.551 165.029 187.901C164.944 187.433 164.809 186.975 164.626 186.533C164.29 185.722 163.798 184.985 163.177 184.364C162.557 183.744 161.82 183.251 161.009 182.915C160.198 182.579 159.328 182.406 158.45 182.406C157.573 182.406 156.703 182.579 155.892 182.915C155.081 183.251 154.344 183.744 153.724 184.364C153.103 184.985 152.611 185.722 152.275 186.533C152.092 186.975 151.957 187.433 151.872 187.901C151.755 188.551 151.23 189.091 150.57 189.091Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M186.863 165.562L176.495 175.931",
      "stroke": "white",
      "stroke-width": "2.79287",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M176.496 165.562L186.864 175.931",
      "stroke": "white",
      "stroke-width": "2.79287",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M140.992 165.562L130.624 175.931",
      "stroke": "white",
      "stroke-width": "2.79287",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M130.625 165.562L140.993 175.931",
      "stroke": "white",
      "stroke-width": "2.79287",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M263.144 19.4864L260.739 24.4434L270.122 25.6365L264.028 35.7232L271.439 43.5346C279.284 41.0962 286.107 35.5816 287.677 27.0659C288.186 24.3237 288.08 21.4585 287.411 18.7324C284.889 8.61422 274.932 3.6353 265.08 5.14771C264.211 5.2751 263.355 5.44891 262.49 5.6759L258.732 17.4738L263.144 19.4864Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M46.6144 71.7916C44.593 72.195 42.7178 73.139 41.5897 74.7764C41.2633 75.2244 41.0099 75.7288 40.8174 76.2802C39.9756 78.7803 40.2401 81.6094 40.8259 84.1227C41.3924 86.485 42.4226 88.6997 43.745 90.7122C45.0131 92.6438 46.6801 94.4363 48.6077 95.8661L49.8484 89.124L57.3232 88.4779L54.4194 83.2029L57.8717 82.582L57.2315 79.5601L64.711 77.0704C59.9165 72.9608 52.7865 70.5676 46.6144 71.7916Z",
      "fill": "#07B53B"
    }
  }), _c('defs', [_c('filter', {
    attrs: {
      "id": "filter0_d_1329_41427",
      "x": "2.4397",
      "y": "110.852",
      "width": "308.535",
      "height": "124.347",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "in": "SourceAlpha",
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
      "result": "hardAlpha"
    }
  }), _c('feOffset', {
    attrs: {
      "dy": "2.09937"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "1.04968"
    }
  }), _c('feComposite', {
    attrs: {
      "in2": "hardAlpha",
      "operator": "out"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in2": "BackgroundImageFix",
      "result": "effect1_dropShadow_1329_41427"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "effect1_dropShadow_1329_41427",
      "result": "shape"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter1_d_1329_41427",
      "x": "88.1339",
      "y": "100.543",
      "width": "140.748",
      "height": "176.267",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "in": "SourceAlpha",
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
      "result": "hardAlpha"
    }
  }), _c('feOffset', {
    attrs: {
      "dy": "14.7411"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "7.37055"
    }
  }), _c('feComposite', {
    attrs: {
      "in2": "hardAlpha",
      "operator": "out"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in2": "BackgroundImageFix",
      "result": "effect1_dropShadow_1329_41427"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "effect1_dropShadow_1329_41427",
      "result": "shape"
    }
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }