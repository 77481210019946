<script>
import Hero from "./components/sections/hero/Hero.vue";

export default {
  components: {
    Hero,
  },
};
</script>
<template>
  <div>
    <Hero />
  </div>
</template>

<style lang="scss" scoped></style>
